<template>
  <div class="main_content">
    <div class="title_box">
      <span></span>
      <h5>社区管理</h5>
    </div>
    <div class="line"></div>
    <div class="operate_box">
      <div class="search_box">
        <div class="input_box">
          <span>地区：</span>
          <!--省市三级联动-->
          <!--<el-cascader-->
          <!--placeholder="请选择"-->
          <!--v-model="regionCode"-->
          <!--:options="options"-->
          <!--:clearable="true"-->
          <!--ref="myCascader"-->
          <!--@change="getAreaCode" v-if="roleId==1">-->
          <!--</el-cascader>-->
          <el-select v-model="searchForm.code" filterable @focus="getArea" clearable>
            <el-option v-for="item in options" :key="item.regionCode" :value="item.regionCode" :label="item.area" />
          </el-select>
        </div>
        <div class="input_box"><el-button type="primary" class="el-icon-search" @click="searchTable"></el-button></div>
      </div>
      <div class="operate_btn">
        <el-button type="warning" plain icon="el-icon-download" size="mini" @click="handleExport">导出</el-button>
        <el-button type="primary" icon="el-icon-plus" @click="addTest">新增</el-button>
        <el-button type="danger" icon="el-icon-delete" @click="deleteTest">批量删除</el-button>
      </div>
    </div>
    <div class="tbale_box">
      <el-table :data="tableData" border style="width: 100%" @selection-change="handleSelectionChange" height="100%">
        <el-table-column type="selection" width="55" />
        <el-table-column fixed prop="id" label="ID" width="100" />
        <el-table-column prop="name" label="社区名称" width="230" />
        <el-table-column prop="province" label="省" width="230" />
        <el-table-column prop="city" label="市" width="230" />
        <el-table-column prop="country" label="区县" />
        <el-table-column prop="code" label="地址编码" />
        <el-table-column prop="createTime" label="创建时间" />
        <el-table-column width="100" label="操作">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
              <el-button type="primary" icon="el-icon-edit" circle @click="editTest(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top">
              <el-button type="danger" icon="el-icon-delete" circle @click="delCommuinty(scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageBox.pageNum"
      :page-sizes="[10, 20, 50, 100, 200, 300, 400]" :page-size="pageBox.pageSize"
      layout="total, sizes, prev, pager, next, jumper" :total="pageBox.total" />
    <!-- <new-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size=pageBox.pageSize
      layout="total,sizes, prev, pager, next" :total=pageBox.total>
    </new-pagination> -->
    <!--新增编辑社区-->
    <el-dialog :visible.sync="editDialog" width="30%" :close-on-click-modal="false" :close-on-press-escape="false"
      class="set_dialog_style">
      <div slot="title" class="dialog_title">
        <span></span>{{ operateBox.title }}
      </div>
      <edit-from :operateBox="operateBox" :key="new Date().getTime()" @closed="closed"></edit-from>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api/index'
import EditFrom from './components/edit-from'
import { chinaArea } from '@/utils/china-area-data'
export default {
  components: { EditFrom },
  data() {
    return {
      tableData: [],
      total: null,
      editDialog: false,
      regionCode: '',
      operateBox: {
        title: '新增',
        operate: 'add',
        id: null
      },
      searchForm: {
        code: ''
      },
      pageBox: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      // roleId:localStorage.getItem('roldId'),
      options: chinaArea
    }
  },
  created() {
    this.getCommunityList(this.searchForm, this.pageBox)
  },
  methods: {
    // 获取社区列表
    async getCommunityList(data, page) {
      try {
        const res = await api.getCommunitySystemUser(data, page)
        if (res.code == 200) {
          this.tableData = res.rows
          this.pageBox.total = res.total
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    },
    // 根据地区查询社区
    getArea() {
      this.options = this.$store.state.userArea
    },
    /* 一页显示多少条切换 */
    handleSizeChange(val) {
      this.pageBox.pageSize = val
      this.getCommunityList(this.searchForm, this.pageBox)
    },
    /* 分页切换 */
    handleCurrentChange(val) {
      this.pageBox.pageNum = val
      this.getCommunityList(this.searchForm, this.pageBox)
    },
    /** 导出按钮操作 */
    async handleExport() {
      try {
        const res = await api.communityDlownd({ ...this.regionCode })
        const name = '社区管理数据'
        const blob = new Blob([res], { type: 'xls' })
        const downloadElement = document.createElement('a')
        const href = window.URL.createObjectURL(blob) // 创建下载的链接
        downloadElement.href = href
        downloadElement.download = `${name}.xls` // 下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href) // 释放掉blob对象
      } catch (e) {

      }
    },
    // 新增样例
    addTest() {
      this.operateBox = {
        title: '添加社区',
        operate: 'add',
        id: null
      }
      this.editDialog = true
    },
    editTest(row) {
      this.operateBox = {
        title: '编辑社区',
        operate: 'edit',
        id: row.id
      }
      this.editDialog = true
    },

    // 选中要删除的居民获取对应的id
    handleSelectionChange(val) {
      const ids = []
      val.forEach(item => {
        ids.push(item.id)
      })
      this.delIds = { ids: ids }
    },
    // 批量删除按钮
    deleteTest() {
      if (this.delIds == null) {
        this.$message.info('请至少选中一条数据进行删除操作')
        return false
      }
      this.$confirm('删除用户会影响小组积分和排名，确定删除该数据吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delCommuinties(this.delIds)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    delCommuinty(row) {
      this.$confirm('删除用户会影响小组积分和排名，确定删除该数据吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delCommuinties({ ids: [row.id] })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },

    // 删除或批量删除社区
    async delCommuinties(ids) {
      try {
        const res = await api.delCommunity(ids)
        if (res.code == 200) {
          this.getCommunityList(this.searchForm, this.pageBox)
        } else {
          this.$message({
            type: 'warning',
            message: res.msg
          })
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    },
    // 条件查询
    searchTable() {
      this.getCommunityList(this.searchForm, this.pageBox)
    },

    // 关闭弹窗
    closed() {
      this.editDialog = false
      this.getCommunityList(this.searchForm, this.pageBox)
    }
  },
  watch: {}
}
</script>

<style scoped lang="less">
.el-pagination {
  text-align: center;
}

/*页面特殊样式*/
.one_tag,
.two_tag,
.three_tag {
  padding: 5px 12px;
  border-bottom-right-radius: 24px;
  border-top-left-radius: 24px;
  color: #fff;
}

.one_tag {
  background: #c60c00;
  background: -webkit-linear-gradient(to bottom, #eb1610, #c66334);
  background: linear-gradient(to bottom, #eb1600, #c65f00);
}

.two_tag {
  background: #eb6601;
  background: -webkit-linear-gradient(to bottom, #eb6601, #c6aa07);
  background: linear-gradient(to bottom, #eb6601, #c6aa07)
}

.three_tag {
  background: #ebd007;
  background: -webkit-linear-gradient(to bottom, #ebd007, #bec606);
  background: linear-gradient(to bottom, #ebd007, #bec606)
}
</style>
