<template>
  <div class="form_box">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="社区名称" prop="name">
        <el-input v-model="ruleForm.name" :maxlength="20"></el-input>
      </el-form-item>
      <div>
        <el-form-item label="详细地址" prop="code">
          <!--省市三级联动-->
          <!--<el-cascader-->
          <!--placeholder="请选择"-->
          <!--v-model="ruleForm.code"-->
          <!--:options="options"-->
          <!--ref="myCascader"-->
          <!--@change="getArea"-->
          <!--&gt;-->
          <!--</el-cascader>-->
          <el-select v-model="ruleForm.code" filterable clearable @change="getAreaCode">
            <el-option v-for="item in options" :key="item.regionCode" :value="item.regionCode" :label="item.area" />
          </el-select>
        </el-form-item>
      </div>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button @click="cancelFrom">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import api from '@/api/index'
import debounce from 'lodash/debounce';
export default {
  props: {
    operateBox: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      ruleForm: {
        name: '',
        code: '',
        city: '',
        country: '',
        province: ''
      },
      options: [],
      rules: {
        name: [
          { required: true, message: '请输入社区名称', trigger: 'change' },
        ],
        code: [
          { required: true, message: '请选择社区所在城市', trigger: 'change' },
        ]
      }
    };
  },
  created() {
    this.options = this.$store.state.userArea
    console.log(this.options)
    if (this.operateBox.id != null) {
      this.getCommityInfo(this.operateBox.id)
    }
  },
  methods: {
    //根据社区id查询社区详情
    async getCommityInfo(id) {
      try {
        let res = await api.getCommuintyById(id)
        if (res.code == 200) {
          this.ruleForm = res.data
        }
      } catch (e) {

      }
    },
    //根据地区查询社区
    getAreaCode(val) {
      let address = ''
      this.options.forEach(item => {
        if (val == item.regionCode) {
          address = item.area
        }
      })
      var array = address.split("/")
      this.ruleForm.province = array[0]
      this.ruleForm.city = array[1]
      this.ruleForm.country = array[2]
    },
    submitForm: debounce(function (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = this.ruleForm
          if (this.operateBox.operate == 'add') {
            this.communityAdd(data)
          } else {
            this.commuintyUpdate(data)
          }
        } else {
          return false;
        }
      });
    }, 500),
    //新增社区
    async communityAdd(p) {
      try {
        let res = await api.addCommunity(p)
        if (res.code == 200) {
          this.$message.success('新增成功！')
          this.$emit('closed')
        } else {
          this.$message.warning(res.msg)
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    },
    //编辑社区
    async commuintyUpdate(p) {
      try {
        let res = await api.updateCommunity(p)
        if (res.code == 200) {
          this.$message.success('修改成功！')
          this.$emit('closed')
        } else {
          this.$message.warning(res.mag)
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    },
    cancelFrom(formName) {
      this.$emit('closed')
    }
  }
}
</script>

<style scoped lang="less">
.el-select {
  width: 100%;
}

.el-cascader {
  width: 100%
}
</style>
